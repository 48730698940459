import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from './equipe.yml'

const EquipePage = () => {
  setTimeout(() => {
    
    document
    .querySelector("#menu")
    .querySelectorAll("li").forEach(li => {
      li.classList.remove('current')
    })
    
    document
    .querySelector("#menu")
    .querySelectorAll("li")[2]
    .classList.add("current");
  }, 2000)

  return (
    <Layout>
      <SEO title={Content.title} />
      <div className="content equipe" dangerouslySetInnerHTML={{__html: Content.content}}></div>
    </Layout>
  )
}

export default EquipePage
